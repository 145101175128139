import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"

import { slugify } from "../../lib/Path"

export default ({ data }) => {
  return (
    <Layout>
      <HeadMeta pageTitle={`Political Parties`} />
      <PageHeader>Political Parties</PageHeader>
      <Breadcrumb
        route={[
          { name: "Parliamentary Papers", href: "/" },
          { name: "Politicial Parties", href: "/parties/" },
        ]}
      />
      <ul>
        {data.allPartyCsv.group.map(partyGroup => (
          <li key={partyGroup.nodes[0].fields.description}>
            <Link
              to={`/party/${slugify(partyGroup.nodes[0].fields.description)}`}
            >
              {partyGroup.nodes[0].fields.description}
            </Link>
          </li>
        ))}
      </ul>
    </Layout>
  )
}

export const query = graphql`
  query PartyIndexQuery {
    allPartyCsv(filter: { fields: { description: { nin: [null, ""] } } }) {
      group(field: fields___description, limit: 1) {
        nodes {
          fields {
            description
          }
        }
      }
    }
  }
`
